<template>
  <div>
    <div class="flex justify-content-end">
      <div>
        <Button :label="$t('actions.submit')" icon="pi pi-check" iconPos="right" :disabled="!hasUpdate" @click="commit"/>
      </div>
    </div>
    <TabView>
      <TabPanel :header="$t('tabs.general')">
        <EditingGeneral />
      </TabPanel>
      <TabPanel :header="$t('tabs.details')">
        Content II
      </TabPanel>
      <TabPanel :header="$t('tabs.rights')">
        Content III
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EditingGeneral from './components/EditingGeneral.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

export default {
  components: {
    TabView, TabPanel,
    EditingGeneral
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters('adminUser', ['user', 'hasUpdate'])
  },
  methods: {
    ...mapActions('adminUser', ['get', 'save']),
    async commit () {
      try {
        await this.save()
        this.$toast.add({severity: 'success', summary: this.$t('users.one.save.success'), life: 4000})
      } catch (err) {
        this.$toast.add({severity: 'error', summary: this.$t('users.one.save.error'), detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    }
  },
  async mounted () {
    const userId = this.$route.params.id
    let title = `${this.$t('users.label', 1)} ${userId}`
    let lastCrumb = {label: title, disabled: true}
    try {
      const {data} = await this.get(userId)
      title = data.username
      lastCrumb = {label: data.username, disabled: true}
    } catch (err) {
      this.$toast.add({severity: 'error', summary: this.$t('users.one.get.error'), detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
    }
    this.$breadcrumb.setCurrent(title, [
      {label: 'Admin', disabled: true},
      {label: 'Users', disabled: false, to: '/admin/users'},
      lastCrumb
    ])
  },
}
</script>