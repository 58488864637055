<template>
  <div>
    <DataTable
    ref="dt"
    :value="users"
    :lazy="true"
    :paginator="true" :rows="perPage" :loading="isLoading" :sortable="true"
    v-model="filters"
    @sort="onSort($event)"
    >
      <template #header>
        <div class="flex justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" :placeholder="$t('actions.search')" @keydown.enter="onFilter"/>
          </span>
          <Button type="button" icon="pi pi-plus" :label="$t('users.new')" class="p-button-outlined" @click="create()"/>
        </div>
      </template>

      <template #empty>
        <div class="flex justify-content-center align-items-center my-6">
          <div class="flex flex-column align-items-center">
            <div> <i class="pi pi-times-circle" style="fontSize: 2rem; color:#aaa"></i> </div>
            <div>
              {{$t('users.list.empty')}}
            </div>
          </div>
        </div>
      </template>

      <Column field="username" :header="$t('common.username')" :sortable="true" filterMatchMode="startsWith" ref="username">
        <template #body="{data}">
          <div class="flex align-items-center">
            <img src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" width="30" />
            <span class="ml-2">{{data.username}}</span>
          </div>
        </template>
      </Column>
      <Column field="email" :header="$t('common.email')" :sortable="true"></Column>
      <Column field="roles" :header="$t('common.roles')">
        <template #body="{data}">
          <span>
            {{ data.roles?.sort().join(' / ') }}
          </span>
        </template>
      </Column>
      <Column field="brand" :header="$t('common.groups')" >
        <template #body="{data}">
          <span>
            {{ data.groups?.sort().join(' / ') }}
          </span>
        </template>
      </Column>
      <Column :exportable="false">
        <template #body="slotProps">
          <div class="flex justify-content-end">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="edit(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-error" @click="remove(slotProps.data)" />
          </div>
        </template>
      </Column>
    </DataTable>
    <UsersNew v-model="showCreateUser"/>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import {FilterMatchMode} from 'primevue/api';
import Log from '@/services/logger'

import UsersNew from './components/UsersNew.vue'

export default {
  components: { DataTable, Column, InputText, Button, UsersNew },
  data: () => ({
    perPage: 10,
    isLoading: true,
    showCreateUser: false,
    filters: {
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    },
    lazyParams: {},
    users: [],
  }),
  mounted () {
    this.$breadcrumb.setCurrent('Users', [
      {label: 'Admin', disabled: true},
      {label: 'Users', disabled: true}
    ])

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    }

    this.loadLazyData()
  },
  methods: {
    onChange () {
      Log('onChange')
    },
    create () {
      this.showCreateUser = !this.showCreateUser
    },
    edit (data) {
      this.$router.push(`/admin/users/${data.id}`)
    },
    loadLazyData() {
      this.isLoading = true

      this.$pxstream.getUsers(this.lazyParams)
      .then( ({data}) => {
        this.users = data.data
      })
      .catch( err => {
        this.$toast.add({severity: 'error', summary: this.$t('users.list.failedToGet'), detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    onPage (event) {
      this.lazyParams = event
      this.loadLazyData()
    },
    onSort (event) {
      this.lazyParams = event
      this.loadLazyData()
    },
    onFilter () {
      this.lazyParams.filters = this.filters
      this.loadLazyData()
    }
  }
}
</script>